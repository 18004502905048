import { PageType } from "@product/scmp-sdk";
import { useDeepCompareMemo } from "@react-hookz/web";
import { useAtomValue } from "jotai";
import { useMemo } from "react";

import type { Props as AdSlotProps } from "scmp-app/components/advertisement/ad-slots/ad-slot";
import {
  TargetingHomePageTakeOverType,
  TargetingPaidType,
} from "scmp-app/components/advertisement/ad-slots/types";
import type { AdUnit, OutstreamAdUnit } from "scmp-app/components/advertisement/ad-slots/types";
import { currentTakeoverAdSectionsAtom } from "scmp-app/components/advertisement/takeover-ad-slot/atoms";
import type { TypeMagazinesStyleVariant } from "scmp-app/components/section/section-style/const";
import { MagazinesStyleVariant } from "scmp-app/components/section/section-style/const";
import { isStyleMainSectionBySectionPath } from "scmp-app/components/section/section-style/helpers";
import { appAtom } from "scmp-app/lib/app/atoms";
import { useEditionValue } from "scmp-app/lib/edition";
import { useIpGeoLocation } from "scmp-app/lib/hooks";
import { rosettaAtom } from "scmp-app/lib/rosetta";
import { useCurrentPageType } from "scmp-app/lib/router/hooks";

type Props = {
  adSlotProps?: Pick<AdSlotProps, "targeting" | "zone">;
  variant?: "home" | TypeMagazinesStyleVariant;
};

export const useTakeoverBottomAdSlot = ({ adSlotProps, variant }: Props) => {
  const { advertisementCampaignConfig } = useAtomValue(appAtom);
  const currentPageType = useCurrentPageType();
  const asyncRosettaState = useAtomValue(rosettaAtom);
  const ipGeoLocation = useIpGeoLocation();
  const currentSectionPath = useAtomValue(currentTakeoverAdSectionsAtom);
  const currentEdition = useEditionValue();

  const currentPageTakeoverAdConfig = useMemo(() => {
    if (variant === MagazinesStyleVariant) return advertisementCampaignConfig.takeoverAd.style;
    if (variant === "home") return advertisementCampaignConfig.takeoverAd.homepage;

    return null;
  }, [
    advertisementCampaignConfig.takeoverAd.homepage,
    advertisementCampaignConfig.takeoverAd.style,
    variant,
  ]);

  const currentPageTakeoverAdSlotInfo = useDeepCompareMemo(() => {
    if (!currentPageTakeoverAdConfig) return;

    return {
      adSlotValue: {
        adUnit: "hpto1" as Exclude<AdUnit, OutstreamAdUnit>,
        autoRefreshOptions: {
          intervalRefreshOptions: {
            isDisable: true,
          },
        },
        targeting: {
          ...adSlotProps?.targeting,
          hpto: TargetingHomePageTakeOverType.Bottom,
          paid: TargetingPaidType.Free,
        },
        zone: adSlotProps?.zone ?? "style/home",
      },
    };
  }, [currentSectionPath, currentPageTakeoverAdConfig, currentPageType]);

  const isMagazineStyleHomepage = useMemo(
    () => isStyleMainSectionBySectionPath(currentSectionPath),
    [currentSectionPath],
  );

  const isPrimaryConditionsToShowAds = useMemo(() => {
    if (!currentPageTakeoverAdConfig || !currentPageTakeoverAdSlotInfo) return false;

    const isTakeoverByDateTimeEnabled = () => {
      const currentTime = new Date();
      const adStartTime = new Date(currentPageTakeoverAdConfig.startDateTime);
      const adEndTime = new Date(currentPageTakeoverAdConfig.endDateTime);

      return (
        currentPageTakeoverAdConfig.enableTakeoverByDateTime &&
        currentTime >= adStartTime &&
        currentTime <= adEndTime
      );
    };

    return currentPageTakeoverAdConfig.takeoverAlwaysEnable || isTakeoverByDateTimeEnabled();
  }, [currentPageTakeoverAdConfig, currentPageTakeoverAdSlotInfo]);

  const isSecondaryConditionsToShowAds = useMemo(() => {
    if (!currentPageTakeoverAdConfig || !currentPageTakeoverAdSlotInfo) return false;

    const isMagazineStyleArticleEnabled =
      variant === MagazinesStyleVariant &&
      currentPageType === PageType.Article &&
      currentPageTakeoverAdConfig.enableInPageTypes.article.enable;

    const isMagazineStyleMainSectionEnabled =
      variant === MagazinesStyleVariant &&
      currentPageType === PageType.Section &&
      isMagazineStyleHomepage &&
      currentPageTakeoverAdConfig.enableInPageTypes.mainSection.enable;

    const isMagazineStyleSubSectionEnabled =
      variant === MagazinesStyleVariant &&
      currentPageType === PageType.SubSection &&
      !isMagazineStyleHomepage &&
      currentPageTakeoverAdConfig.enableInPageTypes.subSection.enable;

    const isHomepageEditionEnabledForAds =
      (currentPageTakeoverAdConfig.takeoverEdition.length === 0 && variant === "home") ||
      (variant === "home" &&
        currentPageTakeoverAdConfig.takeoverEdition.length > 0 &&
        currentPageTakeoverAdConfig.takeoverEdition.includes(currentEdition));

    return (
      isMagazineStyleArticleEnabled ||
      isMagazineStyleMainSectionEnabled ||
      isMagazineStyleSubSectionEnabled ||
      isHomepageEditionEnabledForAds
    );
  }, [
    currentEdition,
    currentPageTakeoverAdConfig,
    currentPageTakeoverAdSlotInfo,
    currentPageType,
    isMagazineStyleHomepage,
    variant,
  ]);

  const isEnableAdSlot = useMemo(() => {
    if (!currentPageTakeoverAdConfig || !currentPageTakeoverAdSlotInfo) return false;

    if (!(isPrimaryConditionsToShowAds && isSecondaryConditionsToShowAds)) return false;

    const isIpAddressEnabledForAds = () => {
      const isAllowAllIps = currentPageTakeoverAdConfig.ipAddress.length === 0;
      if (isAllowAllIps) return true;

      const userIps = ipGeoLocation?.result?.ip.split(",").map(ip => ip.trim()) ?? [];
      return userIps.some(element => currentPageTakeoverAdConfig.ipAddress.includes(element));
    };

    const isUserCountryEnabledForAds = () => {
      const targetCountry = currentPageTakeoverAdConfig.country ?? [];
      if (targetCountry.length === 0) return true;
      const userCountry = ipGeoLocation?.result?.country ?? "";
      return targetCountry.includes(userCountry);
    };

    const handleSubscriberConfigLogic = () => {
      const isNotTargettingEither =
        (!currentPageTakeoverAdConfig.targetSubscribersOnly &&
          !currentPageTakeoverAdConfig.targetNonSubscribersOnly) ||
        (currentPageTakeoverAdConfig.targetSubscribersOnly &&
          currentPageTakeoverAdConfig.targetNonSubscribersOnly);

      if (isNotTargettingEither) return true;

      const isScmpSubscriber = asyncRosettaState?.result?.user?.isSCMPSubscriber ?? false;
      const isSubscriberAndTargetingSubscriber =
        currentPageTakeoverAdConfig.targetSubscribersOnly && isScmpSubscriber;
      const isNonSubscriberAndTargetingNonSubscriber =
        currentPageTakeoverAdConfig.targetNonSubscribersOnly && !isScmpSubscriber;

      return isSubscriberAndTargetingSubscriber || isNonSubscriberAndTargetingNonSubscriber;
    };

    if (
      isIpAddressEnabledForAds() &&
      isUserCountryEnabledForAds() &&
      handleSubscriberConfigLogic()
    ) {
      return true;
    }

    return false;
  }, [
    currentPageTakeoverAdConfig,
    currentPageTakeoverAdSlotInfo,
    isPrimaryConditionsToShowAds,
    isSecondaryConditionsToShowAds,
    ipGeoLocation?.result?.ip,
    ipGeoLocation?.result?.country,
    asyncRosettaState?.result?.user?.isSCMPSubscriber,
  ]);

  return {
    isEnableTakeoverBottomAdSlot: isEnableAdSlot,
    takeoverAdSlotValue: currentPageTakeoverAdSlotInfo?.adSlotValue,
  };
};
